<template>
  <Calendar/>
  
</template>

<script>
//import TimeImport from './components/TimeImport.vue'
import Calendar from './components/Calendar.vue'
//import samJs from '../sam.js';
//import appJs from '../app.js';

export default {
  name: 'App',
  components: {
    //TimeImport ,
    Calendar,
  
    
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
</style>
