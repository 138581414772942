<template>
  <div class="calendar">
    <!-- カレンダーヘッダー -->
    <div class="header">
      <button @click="prevMonth">＜</button>
      <span>{{ currentMonth }}月 {{ currentYear }}年</span>
      <button @click="nextMonth">＞</button>
    </div>

    <!-- カレンダー本体 -->
    <div class="days">
      <!-- 曜日の表示 -->
      <div v-for="day in daysOfWeek" :key="day" class="day-name">{{ day }}</div>

      <!-- 日付の表示 -->
      <div v-for="(day, index) in daysArray" :key="day.date" 
           :class="{
             'is-today': day.isToday, 
             'is-other-month': !day.isInCurrentMonth,
             'sat': index % 7 === 6,
             'sun-or-holiday': index % 7 === 0 || day.isHoliday
           }" 
           class="day">
        {{ day.date.getDate() }}
      </div>
    </div>
  </div>
</template>

<style>
  .calendar {
    max-width: 200px; /* カレンダーの最大幅を200pxに設定 */
    margin-top:0;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    margin-top:0;
  }

  .days {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }



  .day-name,
  .day {
    flex: 1 0 14.28%; /* 100%を7で割った値に設定して均等に分布 */
    padding:3px;
    box-sizing: border-box;
    font-size:12px;
    max-width:28.5px;
  }

  .is-today {
    background-color: #ff0; /* 今日の日付の背景色 */
  }

  .is-other-month {
    color: #aaa; /* 別の月の日付の色 */
  }

  .sat {
    background-color: #ffcccc; /* 土曜日の色を薄い青色に設定 */
  }

  .sun-or-holiday {
    background-color: #ffcccc; /* 日曜日と祝日の色を薄い赤色に設定 */
  }

  /* ...他のスタイルルールがあればここに追加... */
</style>


<script>
import { ref, computed, onMounted } from 'vue';
import { getDaysInMonth, startOfMonth, addDays, getDay, format } from 'date-fns';
import axios from 'axios';

export default {
  setup() {
    const currentMonth = ref(new Date().getMonth());
    const currentYear = ref(new Date().getFullYear());
    const holidays = ref({});

    // 曜日のヘッダーの配列を定義
    const daysOfWeek = ['日', '月', '火', '水', '木', '金', '土'];

    // 祝日データを取得する関数
    async function fetchHolidays(year, month) {
      try {
        const response = await axios.get(`https://holidays-jp.github.io/api/v1/date.json`);
        //console.log(response.data); // 祝日データ
        holidays.value = response.data || {};
      } catch (error) {
        console.error('祝日データの取得に失敗しました。', error);
        holidays.value = {}; // エラー時は祝日情報をクリア
      }
    }

    // コンポーネントがマウントされたときに最初の祝日データを取得
    onMounted(() => {
      fetchHolidays(currentYear.value, currentMonth.value + 1);
    });

    const startDayOfMonth = computed(() => {
      return startOfMonth(new Date(currentYear.value, currentMonth.value));
    });

    const daysInMonth = computed(() => {
      return getDaysInMonth(new Date(currentYear.value, currentMonth.value));
    });

    const startDayOfWeek = computed(() => {
      return getDay(startDayOfMonth.value);
    });

    const daysArray = computed(() => {
      const days = [];
      for (let i = 0; i < startDayOfWeek.value; i++) {
        days.unshift({
          date: addDays(startDayOfMonth.value, -i - 1),
          isInCurrentMonth: false,
          isToday: isSameDay(addDays(startDayOfMonth.value, -i - 1), new Date()),
        });
      }
      for (let i = 0; i < daysInMonth.value; i++) {
        days.push({
          date: addDays(startDayOfMonth.value, i),
          isInCurrentMonth: true,
          isToday: isSameDay(addDays(startDayOfMonth.value, i), new Date()),
          isHoliday: holidays.value[format(addDays(startDayOfMonth.value, i), 'yyyy-MM-dd')] !== undefined,
        });
      }
      while (days.length % 7 !== 0) {
        days.push({
          date: addDays(days[days.length - 1].date, 1),
          isInCurrentMonth: false,
          isToday: false,
          isHoliday: false,
        });
      }
      return days;
    });

    function isSameDay(date1, date2) {
      return date1.getFullYear() === date2.getFullYear() &&
             date1.getMonth() === date2.getMonth() &&
             date1.getDate() === date2.getDate();
    }

    function prevMonth() {
      if (currentMonth.value === 0) {
        currentMonth.value = 11;
        currentYear.value -= 1;
      } else {
        currentMonth.value -= 1;
      }
      fetchHolidays(currentYear.value, currentMonth.value + 1); // 祝日データの更新
    }

    function nextMonth() {
      if (currentMonth.value === 11) {
        currentMonth.value = 0;
        currentYear.value += 1;
      } else {
        currentMonth.value += 1;
      }
      fetchHolidays(currentYear.value, currentMonth.value + 1); // 祝日データの更新
    }

    return {
      currentMonth: computed(() => currentMonth.value + 1), // 人間が読める形式の月 (1-12)
      currentYear,
      daysArray,
      daysOfWeek,
      prevMonth,
      nextMonth
    };
  },
};
</script>
